.signup-logo-container{
    width: 100%;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #7D8CC4;
}

.signup-message-container{
    width: 100%;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 147% */  
    letter-spacing: 0.25px;
    color: #000000;
    opacity: 0.6;
}

.signup-idbox{
    width: 100%;
    height: 10vh;
    display: grid;
    margin-top: 1vh;
}

#signup_email{
    width: 80%;
    height: 6vh;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
}

#signup_password{
    width: 80%;
    height: 6vh;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
}


#confirm-password{
    width: 80%;
    height: 6vh;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
}

#signup-button{
    margin-top: 1vh;
    width: 82%;
    height : 7vh;
    background-color: #7D8CC4;
    border-color: #7D8CC4;
    color : #FFFFFF;
    border-radius: 5px;
    margin-left: 9%;
    margin-right: auto;
}



