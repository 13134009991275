#root{
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0 ; 
}


.outer-container{
    width: 100%;
    height: 100vh;
    background-color: #F5F5F5;
}

.topleft{
    position: relative;
    left: 0;
    top: 0;
    }

.inner-container{
    background-color: #CEF3FF;
    width: 80%;
    height: 70vh;
    margin-left: 10%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    opacity: 0.75;
    box-shadow: 0px 4px 22px #B2DFFF;
    border-radius: 20px;
}

.left{
    
    width: 30%;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.leftsquare{
    margin-left: 10%;
    margin-bottom: 10%;
}

.inner-box{
    width: 40%;
    height: 70vh;
    margin: auto;
}

.right-container{
    width: 30%;
    text-align: right;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.rightsquare{
    margin-top: 10%;
    margin-right: 10%;
}

.bottomright{
    display: flex;
        justify-content: flex-end;
        float: right;
        position: fixed;
        vertical-align: sub;
        bottom: 0;
        right: 0;
        /* opacity: 0.75; */
        
}

/* .dots{
    width: 50%;
}  */
