.totalcontact-container {
    display: flex;
    height: 100vh;
}

.totalcontact-content {

    width: 85%;
    height: 81vh;
}

.header {
    display: flex;
    width: 100%;
    height: 14%;
}

.table-container {
    overflow: hidden;
    height: 60%;
}

.button-container {
    display: flex;
    height: 4.2rem;
}

.right{
    width: 70%;
    display: inline-block;
    text-align: right;
    height: 4rem;
}

.dustbin{
    color: red;
}


.left-button, .right-button{
    height: 4rem;
}