.signin-logo-container{
    width: 100%;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 18px;
/* identical to box height, or 45% */
    letter-spacing: 0.25px;
    color: #7D8CC4;
}

.signin-message-container{
    width: 100%;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 147% */  
    letter-spacing: 0.25px;
    color: #000000;
    opacity: 0.6;
}

.signin-idbox{
    width: 100%;
    height: 10vh;
    display: grid;
    margin-top: 2vh;
}

#login_email{
    width: 80%;
    height: 6vh;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
}

#login_password{
    width: 80%;
    height: 6vh;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
}


#signin-button{
    margin-top: 2vh;
    width: 82%;
    height : 7vh;
    background-color: #7D8CC4;
    border-color: hsl(227, 38%, 63%);
    color : #FFFFFF;
    border-radius: 5px;
    margin-left: 9%;
    margin-right: auto;
}

.signupbox{
    margin-top: 2vh;
    width: 100%;
    height: 10vh;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    /* identical to box height, or 120% */
    display: grid;
    align-items: center;
    letter-spacing: 0.25px;
    color: #7D8CC4;
}

a:-webkit-any-link {
    color: #7D8CC4;
    cursor: pointer;
    text-decoration: none;
}
